import React from "react";
import Banner from "./banner";
import TwoColumnComponent from "./twoColomnComponent";
import Newsletter from "./newsletter/newsletter";
import Posts from "./posts";
import Intro from "./intro";
import HeadingShortDescription from "./heading-shortDescription";
import BannerImage from "./banner-image";
import DescriptionSocials from "./description-socials";

export const Components: { [key: string]: (props: any) => any }  = {
  'banner_one': Banner,
  'blog_post_list': Posts,
  'two_column_with_image_component': TwoColumnComponent,
  'newsletter': Newsletter,
  'entity_intro': Intro,
  'heading_short_description': HeadingShortDescription,
  'banner_image': BannerImage,
  'description_social': DescriptionSocials,
};

const DynamicComponent = ({ blok }: {blok: SBlok}) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component];
    return <Component {...blok} key={blok._uid} />;
  }
  return <p>cannot find component {blok.component}</p>;
};

export default DynamicComponent;
