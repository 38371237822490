import React from "react";
import { Main, SocialProfiles } from "./style";
import WithWrapper from "../withWrapper";
import SocialProfile from '../social-profile/social-profile';
;
import { render } from "storyblok-rich-text-react-renderer";
import IconOptions from "../IconOptions";
import withSbEditable from "../withSBeditable/withSBeditable";


interface headingShortDescription {
withWrapper?: boolean;
content: SFRichText;
links:  {
    link: SFLink;
    name: string;
    icon: string;
  }[];
}

const DescriptionSocials = (props: headingShortDescription) => {

    const { content, links } = props;

    const contactItems =  links?.map(link => {
        const Icon = IconOptions[link?.icon?.toLowerCase()]
        return ({
          icon: <Icon />,
          url: link?.link?.url,
          toottip: link?.name,
        })
      })    

    return (
      <Main>
        {render(content)}

         {!!links?.length && (
          <SocialProfiles>
            <SocialProfile items={contactItems} />
          </SocialProfiles>
        )} 
      </Main>
    );
};




export default withSbEditable(WithWrapper(DescriptionSocials));



