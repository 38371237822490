import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import setPlaceholderColor from '../../utils/setPlaceholderColor';
import FeaturePost from '../feature-post/feature-post';
import { BannerWrapper, BannerInner, FeaturePosts, Title } from './style';
import parseContent from '../../utils/parseContent';
import withSbEditable from '../withSBeditable/withSBeditable';

type BannerProps = SBlok & {
  banner_image: SFAsset,
  list_header: string;
  list_slug: string;
};

const Banner: React.FunctionComponent<BannerProps> = ({banner_image, list_header}) => {

  const Data = useStaticQuery(graphql`
    query {
     featuredPosts: allStoryblokEntry(
        filter: {full_slug: {regex: "posts/featured/"}}
        limit: 5
        sort: {fields: published_at, order: DESC}
      ) {
        nodes {
          full_slug
          content
          id
          tag_list
          name
        }
      }
    }
  `);

  const Posts = Data.featuredPosts.nodes;
  

  return (
    <BannerWrapper imgUrl={banner_image?.filename}>
      <BannerInner>
        <FeaturePosts>
          <Title>{list_header}</Title>
          {Posts.map((post: any) => {
            // Random Placeholder Color

            const color = setPlaceholderColor();
            const content = parseContent(post.content)

            return (
              <FeaturePost
                key={post.id}
                title={post.name}
                image={content.banner_image}
                url={post.full_slug}
                tags={post.tag_list}
                placeholderBG={color}
              />
            );
          })}
        </FeaturePosts>
      </BannerInner>
    </BannerWrapper>
  );
};

export default withSbEditable(Banner);
