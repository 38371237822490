import * as React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Button from '../button/button';
import PostCardModern from '../post-card-modern/post-card-modern';
import parseContent from '../../utils/parseContent';
import BlogPostsWrapper, { PostRow, PostGrid, SeeMore } from './style';

type PostsProps = SBlok & { count: number};

const Posts: React.FunctionComponent<PostsProps> = () => {

  const { allStoryblokEntry : { edges }} = useStaticQuery(graphql`
    query {
      allStoryblokEntry(
        filter: {field_component: {eq: "blog_post"}}
        sort: {order: DESC, fields: published_at}
        limit: 12
      ) {
        edges {
          node {
            id
            field_component
            name
            content
            tag_list
            slug
            full_slug
            uuid
            published_at(formatString: "DD/MMM")
          }
        }
      }
    }
  `);


  return (
    <BlogPostsWrapper>
      <PostRow>
         {edges.map(({ node }: any) => {

           const content = parseContent(node.content);
          // Random Placeholder Color
          const placeholderColors = [
            '#55efc4',
            '#81ecec',
            '#74b9ff',
            '#a29bfe',
            '#ffeaa7',
            '#fab1a0',
            '#e17055',
            '#0984e3',
            '#badc58',
            '#c7ecee',
          ];
          const setColor =
            placeholderColors[
              Math.floor(Math.random() * placeholderColors.length)
            ];

          return (
            <PostGrid key={node.full_slug}>
              <PostCardModern
                key={node.full_slug}
                title={node.name}
                image={content.banner_image}
                url={node.full_slug}
                description={content.description}
                date={node.published_at}
                tags={node.tag_list}
                placeholderBG={setColor}
              />
            </PostGrid>
          );
        })} 
      </PostRow>
      <SeeMore>
        <Link to="page/1">
          <Button title="See more" type="submit" />
        </Link>
      </SeeMore>
    </BlogPostsWrapper>
  );
};

export default Posts;
