
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const Main = styled.div`
width: 870px;
max-width: 100%;
margin: 0 auto;

h2 {
  font-size: 21px;
  font-weight: 500;
  color: ${themeGet('colors.textColor', '#292929')};
//   margin-bottom: 40px;
  @media (max-width: 990px) {
    // margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 18px;
    // margin-bottom: 25px;
  }
}
`;


export const SocialProfiles = styled.div`
margin-top: 60px;
position: relative;
@media (max-width: 767px) {
  margin-top: 40px;
}

&:before {
  content: '';
  width: 30px;
  height: 1px;
  background: #292929;
  display: block;
  margin-bottom: 60px;
  @media (max-width: 767px) {
    margin-bottom: 40px;
  }
}
`;
