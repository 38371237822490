import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 90px 75px 0 75px;

  & ~ & {
    padding-top: 45px
  }

  @media (max-width: 990px) {
    padding: 80px 45px 0px 45px;
    & ~ & {
      padding-top: 45px
    }

  }
  @media (max-width: 575px) {
    padding: 60px 25px 0 25px;
    & ~ & {
      padding-top: 45px
    }
  }
`;