import React from "react";
import { Image } from "./style";
import WithWrapper from "../withWrapper";
import { FluidImage } from "../gatsby-image";
import withSbEditable from "../withSBeditable/withSBeditable";

interface BannerImageProp {
  withWrapper?: boolean;
  image: SFAsset
}

const BannerImage = (props: BannerImageProp) => {
  const { image   } = props;
  return (
    <Image>
      <FluidImage blok={{filename: image?.filename}} alt={image?.alt || 'parent gist'} />
    </Image>
  );
};

export default withSbEditable(WithWrapper(BannerImage));
