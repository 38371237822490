import React from "react";
import { sbEditable } from "@storyblok/storyblok-editable";
import Page from "../components/Page";
import useStoryblok from "../utils/storyblok";

interface PageProps {
  pageContext: Record<string, any>;
  location: any;
}

const Components: { [key: string]: (props: any) => JSX.Element } = {
  page: Page,
};

export default function MainComponent({ pageContext, location }: PageProps) {
  const story = useStoryblok(pageContext.story, location);

  if (typeof Components[story?.content.component] !== "undefined") {
    const Component = Components[story?.content.component];
    return (
        <div {...sbEditable(story.content)}>
          <Component blok={story.content} story={story} />
        </div>
    );
  }
  return <p>Component {story.content.component} not found</p>;
}
