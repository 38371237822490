import styled from 'styled-components';

export const Image = styled.div`
  // margin-bottom: 90px;
  @media (max-width: 990px) {
    // margin-bottom: 60px;
  }
  @media (max-width: 767px) {
    // margin-bottom: 40px;
  }
`;