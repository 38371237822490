import * as React from 'react';
import { FluidImage } from '../gatsby-image';
import { Link } from 'gatsby';
import { IoMdArrowRoundBack } from 'react-icons/io';
import {
  NotFoundWrapper,
  NotFoundContent,
  NotFoundImage,
  Goback,
  Icon,
} from './style';

interface NotFoundProps {}

const NotFound: React.FunctionComponent<NotFoundProps> = ({heading, lead, banner_image, link}: any) => {

  const returnUrl = link.cached_url === 'home' ? '/' : link.cached_url
  return (
    <NotFoundWrapper>
      <NotFoundContent>
        <h1>{heading}</h1>
        <p>
         {lead}
        </p>
        <Goback>
          <Link to={returnUrl}>
            <Icon>
              <IoMdArrowRoundBack />
            </Icon>
            Go Back
          </Link>
        </Goback>
      </NotFoundContent>
      <NotFoundImage>
        <FluidImage blok={banner_image} alt="author" />
      </NotFoundImage>
    </NotFoundWrapper>
  );
};

export default NotFound;
