import React from "react";
import DynamicComponent from "./DynamicComponent";
import Layout from "./layout";
import Seo from "./seo";

const Page = ({ blok }: { blok: PageContent; story: SStory }) => {
  const content =
    blok?.body &&
    blok?.body.map((childBlok) => (
      <DynamicComponent blok={childBlok} key={childBlok._uid} />
    ));

  return (
    <Layout>
      <Seo title={blok.title} description={blok.description} />
      {content}
    </Layout>
  );
};

export default Page;
