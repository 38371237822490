import React from "react";
import { HeadingShortDescriptionComponent } from "./style";
import WithWrapper from "../withWrapper";
import withSbEditable from "../withSBeditable/withSBeditable";


interface headingShortDescription {
withWrapper?: boolean;
heading: string;
description?: string;
}

const HeadingShortDescription = (props: headingShortDescription) => {
    const { heading, description } = props;
  return (
    <HeadingShortDescriptionComponent>
      <h2>{heading}</h2>
      <p>
       {description}
      </p>
    </HeadingShortDescriptionComponent>
  );
};




export default withSbEditable(WithWrapper(HeadingShortDescription));



