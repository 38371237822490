import React from "react";
import { Wrapper } from "./style";

export default function WithWrapper<T>(NotWithPropsComponent: React.FC<T>) {
  const Component: React.FC<T & { withWrapper?: boolean }> = (props) => {
    if (!props.withWrapper) return <NotWithPropsComponent {...props} />;
    return (
      <Wrapper>
        <NotWithPropsComponent {...props} />
      </Wrapper>
    );
  };

  return Component;
}
