import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

export const HeadingShortDescriptionComponent = styled.div`
  p {
    margin-bottom: 0px;
  }
  h2 {
    font-size: 30px;
    font-weight: 700;
    color: ${themeGet('colors.textColor', '#292929')};
    line-height: 1.53;
    margin-bottom: 10px;
    @media (max-width: 990px) {
      font-size: 26px;
    }
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
`;